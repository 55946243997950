import React from 'react'
import Layout from 'components/layout'
import SearchFull from 'components/search/searchFull'
import Title from 'components/title'
import { graphql } from 'gatsby'

const SearchPage = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  // const translations = data.translations;
  // const tr = (code) => trFunction(code, translations, locale);
  const page = data.thisPage
  return (
    <Layout>
      <div id="content">
        <Title title={page.title} />
        <section className="page-content wrap">
          <SearchFull data={data} />
        </section>
      </div>
    </Layout>
  )
}
export default SearchPage

export const searchPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "search" }) {
      title
      slug
    }
  }
`
