import LinkWrap from 'components/LinkWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import React from 'react'
import {
  Configure,
  connectStateResults,
  Hits,
  InstantSearch,
  SearchBox,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import 'url-search-params-polyfill'
import RImage from 'components/rImage'

const Hit = ({ hit }) => {
  const rParam = { class: 'lazyload responsive' }
  return (
    <div className="hit item-result item-width-image">
      <LinkWrap
        className="image-holder"
        to={'/' + hit.catagory + '/' + hit.link}
      >
        <div className="item-picture">
          <div className="img-size">
            <RImage src={hit.image} rParam={rParam} />
          </div>
        </div>
      </LinkWrap>
      <div className="item-text text-thumbnail">
        <div className="small-caps">
          <LinkWrap to={'/' + hit.catagory}>{hit.subtitle}</LinkWrap>
        </div>
        <div className="title">
          <LinkWrap className="dark" to={'/' + hit.catagory + '/' + hit.link}>
            {hit.title}
          </LinkWrap>
        </div>
        {hit.content && (
          <div className="text">
            <MarkdownWrap body={hit.content} />
          </div>
        )}
      </div>
    </div>
  )
}

const Content = connectStateResults(({ searchState, searchResults }) => {
  // const hasQuery = searchState && searchState.query && searchState.query !== "";
  // const hasInputValue = (document.querySelector('.page-content.wrap input').value != false);

  // if (hasQuery || hasInputValue) {

  return (
    <div className="search-results-container">
      <div id="info">{/* <Stats /> */}</div>

      <Configure hitsPerPage={40} />
      <div id="hits">
        <Hits hitComponent={Hit} />
      </div>

      {/* <div id="pagination">
            <Pagination  showLast />
        </div> */}
    </div>
  )

  // } else {
  //   return false;
  // }
})

const SearchFull = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  // const translations = data.translations;
  // const tr = (code) => trFunction(code, translations, locale);

  let q = ''
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)
    q = urlParams.get('q')
  }
  const searchClient = algoliasearch(
    'JX10GM8E7U',
    '4549c387c67d367a328f4c31cf6ef599',
  )

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={'searchPage_' + locale}
    >
      <SearchBox translations={{ placeholder: '' }} defaultRefinement={q} />
      <Content />
    </InstantSearch>
  )
}
export default SearchFull
