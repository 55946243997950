import React, { Component } from 'react'
import Inview from 'components/Inview'

class RImage extends Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      isInView: false,
      sizes: '2px',
      forceShow: false,
    }
    this.setSizes = this.setSizes.bind(this)
  }

  componentDidMount() {
    this.setSizes()
    window.addEventListener('resize', this.setSizes)
  }

  componentWillUnmount() {
    window.addEventListener('resize', null)
  }
  setSizes() {
    if (this.img) {
      this.setState({ sizes: this.img.offsetWidth * 1.5 + 'px' })
    }
  }

  render() {
    let src = this.props.src
    let rParam = this.props.rParam

    let processParam = {}
    if (rParam.quality) {
      processParam.quality = rParam.quality
    }
    if (rParam.format) processParam.format = rParam.format
    if (rParam.fit) processParam.fit = rParam.fit
    if (rParam.focus) processParam.focus = rParam.focus
    const srcOriginal = processImageUrl(src, processParam)

    let srcsetArray = []
    let srcsetWidths = rParam.srcsetWidths || [
      2800, 2400, 2000, 1600, 1200, 800, 600, 400,
    ] // default

    srcsetWidths.forEach((srcWidth, index) => {
      processParam.width = srcWidth
      if (rParam.heightRatio) {
        // crop/fill
        processParam.height = Math.round(srcWidth * rParam.heightRatio)
        if (processParam.height > 4000) return // contentful limit
        processParam.fit = 'fill'
      }
      const thisImage = processImageUrl(src, processParam)
      const thisString = `${thisImage} ${srcWidth}w`
      srcsetArray.push(thisString)
    })

    const srcsetString = srcsetArray.join(', ')
    const width = rParam.width || ''
    const height = rParam.height || ''

    return (
      <Inview className="trigger-in-view fade">
        <img
          ref={(img) => {
            this.img = img
          }}
          width={width}
          height={height}
          data-src={srcOriginal}
          className={
            rParam.class + ' ' + (this.state.loaded ? 'lazyloaded' : ' ')
          }
          sizes={width ? width + 'px' : this.state.sizes} // for hidden images (ex: collection products slideshow), force initial width
          srcSet={srcsetString} // testing
          onLoad={() => this.setState({ loaded: true })}
          alt=""
        />
      </Inview>
    )
  }
}
export default RImage

const processImageUrl = (src, param = {}) => {
  let imageUrl = src
  let urlParam = []
  const format = param.format || 'jpg'
  urlParam.push(`fm=${format}`) // format
  if (format === 'jpg') {
    urlParam.push('fl=progressive') // progressive jpg
    const quality = param.quality || 30
    urlParam.push(`q=${quality}`) // jpg quality
  }
  const width = param.width || 1500
  urlParam.push(`w=${width}`) // width
  const height = param.height || undefined
  if (height) urlParam.push(`h=${height}`) // height
  const fit = param.fit || undefined
  if (fit) urlParam.push(`fit=${fit}`) // fit: pad, crop, fill, thumb, scale
  const focus = param.focus || undefined
  if (focus) urlParam.push(`f=${focus}`) // top, right, left, bottom, bottom_right etc, face, faces
  if (urlParam.length > 0) {
    const urlString = urlParam.join('&')
    imageUrl += '?' + urlString
  }
  return imageUrl
}
